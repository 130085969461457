#footer {
    font-family: "Verdana", "Geneva", sans-serif;
    background: #2A2D30;
    color: #8D99AE;
    height: 30vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footer__copyright {
    padding: .5em;
}

.footer__copyright * a {
    color: #FF9100;
    text-decoration: none;
}
.footer__copyright * a:hover {
    text-decoration: underline;
}

.footer__copyright > p {
    font-size: .75em;
    margin: 0;
}

.footer__socialmenu {
    padding: .75em 2.5em;
}

.footer__socialmenu > a {
    margin: auto;
    padding: 1em;
    line-height: 2em;
    text-align: center;
}
.footer__socialmenu a:hover {
    background: var(--color-off-white);
}

.footer__socialmenu * img {
    margin: auto;
    vertical-align: middle;
    height: 2em;
    width: 2em;
}
.footer__socialmenu__logo {
    height: 2em;
    width: 2em;
}