.timerControls__session__container,
.timerControls__break__container {
    display: inline-block;
    padding: .75em;
    margin: 1em;

    background-color: var(--main-color-bg);
    color: var(--main-color-fg);
    border: .125em solid var(--main-color-fg);
}

.timerControls__time_header {
    padding: .25em;
}

.timerControls__time__text {
    padding: .5em;
    margin: 0;
    font-size: 1.5em;
    line-height: 2;
}

.timerControls__btn {
    padding: .5em;
    margin: .5em;
    background-color: var(--main-color-fg);
    border: 0;
}
.timerControls__btn:hover {
    background-color: var(--main-color-hover);
}

.arrow-up,
.arrow-down {
    border: 0 solid var(--main-color-bg);
    border-width: 0 .25em .25em 0;
    display: inline-block;
    padding: .25em;
}
.arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
.arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}