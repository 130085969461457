html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }
html, body {
    margin: 0;
    padding: 0;
    font-family: "Verdana", "Geneva", sans-serif;
}
h1, h2, h3 {
    margin: 0;
    font-weight: 400;
}
ul { margin-top: 0; }