.controls__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.controls__btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: .25em;
    padding: 0 .5em;
    font-size: 1.5em;

    background-color: var(--main-color-bg);
    color: var(--main-color-fg);
    border: .075em solid var(--main-color-fg);
}

.controls__btn:hover {
    color: var(--main-color-hover);
    border: .075em solid var(--main-color-hover);
}

.controls__btn__icon {
    font-size: 1.25em;
    margin: .25em;
}