#pomodoro {
    --main-color-bg: #006596;
    --main-color-fg: #009eea;
    --main-color-hover: orange;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100vh;
    padding: 1em;
    margin: auto;

    background-color: var(--main-color-bg);
    color: var(--main-color-fg);
    text-align: center;
}