.navbar__container {
    --main-bg: #eaeaea;
    --main-fg: black;
    --icon-fill: gray;
    --icon-fill-hover: #009eea;
}

.navbar__container {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;

    background-color: var(--main-bg);
    color: var(--main-fg);
}

.greetings__container, 
.mode__icon, 
.login__icon, 
.home__icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.greetings__container > img {
    width: 4em;
    padding: .5em;
}

.navbar__container * svg {
    margin: 0 .5em;
    font-size: 1.75em;
    color: var(--icon-fill);
}

.navbar__container * svg:hover {
    color: var(--icon-fill-hover);
}