#drum-machine {
    --main-color-bg: #006596;
    --main-color-fg: #009eea;

    --main-color-bg-hover: orange;
    --main-color-fg-hover: white;
}

.drum__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100vw;

    background-color: var(--main-color-bg);
    color: var(--main-color-fg);
}

.drum__title {
    font-size: 1.25em;
    padding: 1.5em;
    margin: .75em 0;

    background-color: var(--main-color-fg);
    color: var(--main-color-bg);
}

.drum__display {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: .25em;
    padding: .25em 1em;

    font-size: 2.5em;
    background-color: var(--main-color-fg);
    color: var(--main-color-bg);
}

.drum__set__wrapper {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    border: .125em solid var(--main-color-fg);
    padding: 1em;
}

.drum-pad {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3.5em;
    width: 3.5em;
    margin: .3em;

    font-size: 1.75em;
    background: var(--main-color-fg);
    color: var(--main-color-bg);
}

.drum-pad:hover {
    background: var(--main-color-bg-hover);
    color: var(--main-color-fg-hover);
}