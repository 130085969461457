.displayScreen__container {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 1em;
}

.displayScreen__circle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 3em;

    /** (h === w : 50%) === a circle! **/
    height: 25em;
    width: 25em;
    border-radius: 50%;
    border: .25em var(--main-color-fg) solid;
}

.displayScreen__header{
    font-weight: bold;
    font-size: 2.125em;
}

.displayScreen__text {
    font-size: 6em;
    padding: .5em 0 .25em;
}