#md {
    --editor-bg-color: #009eea;
    --editor-fg-color: #ffffff;
    --preview-bg-color: #eaeaea;
    --preview-fg-color: #000000;
    --header-font: monospace;
    --header-bg-color: #006596;
    --header-fg-color: #ffffff;
    --md-fa-icon-fill: #ffffff;
}

.md__title {
    background-color: var(--preview-bg-color);
    color: var(--header-bg-color);
    margin: .75em;
    padding: .75em;
    margin-bottom: 0;

    font: bolder 3.25em monospace;
    text-align: center;

    border: 1px var(--editor-bg-color) solid;
    border-radius: .25em;
}

.md__container {
    display: flex;
    align-content: center;
    justify-content: space-around;

    margin: 0;
    padding: .5em;
    width: 100vw;
}

.md__editor__container,
.md__preview__container {
    flex: 1;
    padding: 1em;
}

.md__preview__container {
    flex-basis: 25%;
}

.md__header__container {
    display: flex;
    flex-direction: row;

    background-color: var(--header-bg-color);
    color: var(--header-fg-color);

    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
}

.md__header__title, .md__header__icon {
    padding: .75em;
    font: bolder 1.5em var(--header-font);
}

.md__header__title {
    padding-left: 0;
}

.md__header__icon > svg {
    fill: var(--md-fa-icon-fill);
}

#editor, #preview {
    padding: .75em;
    flex: 1;
}

#editor {
    background-color: var(--editor-bg-color);
    color: var(--editor-fg-color);
    font: .8em monospace;
    width: 100%;
}

#preview {
    background-color: var(--preview-bg-color);
    color: var(--preview-fg-color);
    font-size: .8em;
}

/********************* MEDIA QUERIES ***************************/
@media (max-width: 899px){ /* mobile */
    .md__title {
        font-size: 1em;
    }
    .md__container {
        flex-direction: column;
    }
}