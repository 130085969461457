.quote__container {
    --quote-bg-color: orange;
    --quote-fg-color: white;
    --svg-qoute-icon-color: red;
    --svg-social-icon-color: white;
    --svg-social-icon-color-hover: #007EBB;
}

.quote__container {
    background: url(../../images/redmapleleaf.jpg) no-repeat fixed;
    background-size: cover;

    height: 100vh;
    width: 100vw;

    display: flex;
    align-content: center;
    justify-content: center;
}

#quote-box {
    display: flex;
    flex-direction: column;

    text-align: center;
    margin: auto;
    padding: 1.4em;

    min-width: 25em;
    max-width: 75vw;

    background-color: var(--quote-bg-color);
    color: var(--quote-fg-color);
    border-radius: .75em;
}

.quote__text__wrapper {
    display:flex;
    justify-content: center;
    flex-direction: column;
}

.quote__quoted__lquote,
.quote__quoted__rquote {
    color: var(--svg-qoute-icon-color);
}

.quote__quoted__lquote {
    text-align: left;
}

#text {
    font-size: 2em;
}

.quote__quoted__rquote {
    text-align: right;
}

#author { display: inline; }
#author, .author-name {
    font-size: 1.25em;
}

.quote__social__icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1em 0 0 0;
}

.quote__social__icon__new,
.quote__social__icon__retweet {
    padding: 0 .45em;
}

.quote__social__icons * svg {
    fill: var(--svg-social-icon-color);
}
.quote__social__icons * svg:hover {
    fill: var(--svg-social-icon-color-hover);
}