.calc__container {
    --main-color-bg: #006596;
    --main-color-fg: #009eea;

    --main-color-bg-hover: orange;
    --main-color-fg-hover: white;
}


.calc__container {
    background: url(../../images/redmapleleaf.jpg);
    background-size: cover;

    display: flex;
    align-content: center;
    justify-content: space-around;

    margin: 0;
    padding: .5em;
    width: 100vw;
    height: 100vh;
}

#calculator {
/*     width: 80vw; */
    margin: auto;
    padding: 1em;
    background-color: var(--main-color-bg);

    /* border: 2px solid yellow; */
}




#display {
    background-color: var(--main-color-fg);
    /* height: 10vh; */
    /* width: 50vw; */
    width: 100%;
    padding: .5em;
    border: 0;
    margin: auto;

    color: var(--main-color-bg);
    font-size: 2.5em;
    text-align: center;
}



.btncalc {
    background-color: var(--main-color-bg);
    color: var(--main-color-fg);

    font-weight: bolder;
    font-size: 1.25em;

    width: 50px;
    height: 50px;

    padding: 2em;
    margin: .1em;
    border: 0;

    border: .025em solid var(--main-color-fg);
}

.btncalc:hover {
    background-color: var(--main-color-bg-hover);
    color: var(--main-color-fg-hover);
}



#ac { grid-area: ac; }
#ce { grid-area: ce; }
#decimal { grid-area: decimal; }
#equals { grid-area: equals; }

#add { grid-area: add; }
#subtract { grid-area: subtract; }
#multiply { grid-area: multiply; }
#divide { grid-area: divide; }

#zero { grid-area: zero; }
#one { grid-area: one; }
#two { grid-area: two; }
#three { grid-area: three; }
#four { grid-area: four; }
#five { grid-area: five; }
#six { grid-area: six; }
#seven { grid-area: seven; }
#eight { grid-area: eight; }
#nine { grid-area: nine; }

.grid {
    display: grid;
    grid-template-areas: 
        "ac ac ce divide"
        "seven eight nine multiply"
        "four five six subtract"
        "one two three add"
        "zero zero decimal equals"
    ;

    grid-auto-rows: max-content;
    grid-auto-columns: max-content;
    grid-gap: .45em;

    padding-top: .5em;
    /* align-items: center; */
    /* place-items: center center; */
}